'use client'

import { setToken } from '~/lib/cookie'
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Button
} from '@mantine/core'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { debounce } from '@gym-rose/common'
import { loginReqZod, registerReqZod } from '@gym-rose/data/rest'
import { apiClient } from '~/lib/request'
import { useRouter } from 'next/navigation'
import { useUser } from '~/hooks/use-user'

function RegisterComponent() {
  const [phoneNum, setPhoneNum] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const router = useRouter()
  const { mutate } = useUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const register = useCallback(
    debounce(() => {
      const result = registerReqZod.safeParse({ phoneNum, password })
      if (!/^1[3-9]\d{9}$/.test(phoneNum)) {
        toast.error('请输入有效电话号码', { autoClose: 2000 })
        return
      }
      if (!result.success) {
        toast.error('请输入 6 到 20 位密码', { autoClose: 2000 })
        return
      }
      if (password !== confirmPassword) {
        toast.error('两次密码不一致', { autoClose: 2000 })
        return
      }

      apiClient
        .register('', { phoneNum, password })
        .then(res => {
          setToken(res.data.token)
          router.push('/dashboard')
          mutate()
        })
        .catch(err => {
          toast.error(err.message, { autoClose: 2000 })
        })
    }, 500),
    [phoneNum, password, confirmPassword]
  )

  return (
    <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
      <TextInput
        label='电话号码'
        required
        value={phoneNum}
        onChange={e => setPhoneNum(e.target.value)}
      />
      <PasswordInput
        label='密码'
        required
        placeholder='请输入 6 到 20 位密码'
        mt='md'
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <PasswordInput
        label='确认密码'
        required
        mt='md'
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
      />
      <Button fullWidth mt='xl' onClick={register}>
        注册
      </Button>
    </Paper>
  )
}

function LoginComponent() {
  const [phoneNum, setPhoneNum] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter()
  const { mutate } = useUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const login = useCallback(
    debounce(async () => {
      const result = loginReqZod.safeParse({ phoneNum, password })
      if (!/^1[3-9]\d{9}$/.test(phoneNum)) {
        toast.error('请输入有效电话号码', { autoClose: 2000 })
        return
      }
      if (!result.success) {
        toast.error('请输入 6 到 20 位密码', { autoClose: 2000 })
        return
      }

      apiClient
        .loginWithPhoneNum('', { phoneNum, password })
        .then(res => {
          setToken(res.data.token)
          router.push('/dashboard')
          mutate()
        })
        .catch(err => {
          console.log('err', err)
          toast.error(err.message, { autoClose: 2000 })
        })
    }, 500),
    [phoneNum, password]
  )

  return (
    <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
      <TextInput
        label='电话号码'
        required
        value={phoneNum}
        onChange={e => setPhoneNum(e.target.value)}
      />
      <PasswordInput
        label='密码'
        required
        placeholder='请输入 6 到 20 位密码'
        mt='md'
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button fullWidth mt='xl' onClick={login}>
        登录
      </Button>
    </Paper>
  )
}

export function Authentication() {
  const [isRegistering, setIsRegistering] = useState(false)
  const { user, loading } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (user) {
      router.push('/dashboard')
    }
  }, [user, router])

  return (
    !user &&
    !loading && (
      <Container size={420} my={40}>
        <Title ta='center'>欢迎回来</Title>
        <Text c='dimmed' size='sm' ta='center' mt={5}>
          {isRegistering ? '已有帐号?' : '没有帐号?'}
          <Anchor
            size='sm'
            component='button'
            onClick={() => setIsRegistering(prev => !prev)}
          >
            {isRegistering ? '立即登录' : '立即注册'}
          </Anchor>
        </Text>
        {isRegistering ? <RegisterComponent /> : <LoginComponent />}
      </Container>
    )
  )
}
